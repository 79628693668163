@import "_variables";
html{
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 90%;
  }
}
body{

}
.title-image-back{
  .tag{
    h1{
      font-size: 3rem;
    }
  }
}
.navbar-brand{
  img{
    @media(max-width: 767px){
      max-width: 90%;
      margin-left: 10%;
    }
  }
}
nav.navbar.bootsnav {
  background-color: #EDE2D6;
  ul.nav > li {

     a{
      @media(min-width: 991px){
        padding: 30px 25px;
      }
      color: $primary_color;
      font-weight: 400;
      text-transform: uppercase;
      font-family: $global_font;
    }
  }
}
p{
  font-size: 1.4rem;
}
h2{
  font-size: 2.4rem;
}
h3{
  font-size: 2rem;
}

.background{
  &-primary{
    background-color: $primary_color;
  }
  &-secondary_color{
    background-color: $secondary_color;
  }
}
.btn{
  border-radius: 8px;
  text-transform: uppercase;
}
nav.navbar.bootsnav .navbar-toggle{
  background-color: transparent;
}
.button-link{
  a{
    @media(min-width: 991px){
      background-color: $primary_color!important;
      color: #FFFFFF!important;
      padding: 7px 25px!important;
      border-radius: 8px;
      text-transform: uppercase;
      margin-top: 22px;
      margin-left: 30px;
    }
  }
}
.gform_wrapper ul.gform_fields li.gfield{
  margin-top: 10px!important;
}

.base-footer{
  .gform_footer{
    margin: 0!important;
    padding: 0!important;
  }
  .col-md-6{
    padding: 0px 50px;
    @media(max-width: 991px){
      padding: 0 15px;
    }
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $body_text_color;
    font-weight: 600!important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $body_text_color;
    font-weight: 600!important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color:  $body_text_color;
    font-weight: 600!important;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $body_text_color;
    font-weight: 600!important;
  }
  .gform_wrapper li.hidden_label input, .gform_wrapper li.hidden_label textarea{
    margin-top: 0!important;
    border-radius: 8px!important;
    border: none!important;
    font-size: .9rem!important;
    padding: 8px 12px!important;
    width:100%!important;
  }
  .button{
    background-color: $secondary_color!important;
    color: $body_text_color!important;
    margin-top: 0!important;
    padding: 7px 25px!important;
  }
  p{
    font-size: 1.7rem;
    font-weight: 400;
    color: $secondary_color;
  }
  h3{
    font-family: 'Playfair Display', serif!important;
    font-weight:900!important;
    font-style:italic!important;
    letter-spacing:1px!important;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 2.8rem;
    margin-bottom: 20px;
    &:after{
      border:none;
      height: 0;
    }
  }
   h4{
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 1.9rem;
    font-family: 'Raleway', sans-serif;
    color: $secondary_color;
    margin-bottom: 0;
    &:after{
      border:none;
      height: 0;
    }
  }

}
.sub-footer{
  background-color: #4F001C;
  padding: 15px 0;
  text-align: center;
  p,a{
    color: #ffffff!important;
    font-size: 1.1rem;
    margin: 0;
    font-family: 'Playfair Display', serif;
  }
}

/* Image Carousel*/
#homeCarousel{
  background-color: $secondary_color;
  padding: 50px 0;
  .caption{
    padding: 15px;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    @media(max-width: 767px){
      min-height: 85px;
    }
  }
}
.carousel-control{
  background: none!important;
  background-image: none!important;
  opacity: 1!important;
  width: 75px;
  height: 130px;
  top:35%;
  img{
    position: absolute;
    width: 75px;
    height: 130px;
    @media(max-width: 991px){
      width: 40px;
      height: 71px;
    }
  }
  &.left{
    img{
      left: 0;
    }
  }
  &.right{
    img{
      right: 0;
    }
  }
}
